    import React from 'react';
    import { Container, Row, Col, Nav } from "react-bootstrap";
    
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faXTwitter, faMedium, faDiscord } from '@fortawesome/free-brands-svg-icons';
    
    import Logo from "../assets/logo-128.png";
    
    const {
      REACT_APP_CONTRACT_ADDRESS: contractAddress,
      REACT_APP_CONTRACT_CHAIN_EXPLORER: contractChainExplorer,
      REACT_APP_ADOPT_CONTRACT_ADDRESS: adoptContractAddress,
      REACT_APP_ADOPT_CONTRACT_CHAIN_EXPLORER: adoptContractChainExplorer,
      REACT_APP_MARMOTTOSHOW_CONTRACT_ADDRESS: marmottoshowContractAddress,
      REACT_APP_MARMOTTOSHOW_CONTRACT_CHAIN_EXPLORER: marmottoshowContractChainExplorer,
      REACT_APP_VERSION: version
    } = process.env;
    
    const socialLinks = [
      {
        href: "https://discord.com/invite/5kMFzYr6bt",
        icon: faDiscord,
        title: "Discord"
      },
      {
        href: "https://twitter.com/Marmottoshis/",
        icon: faXTwitter,
        title: "Twitter"
      },
      {
        href: "https://medium.com/@Marmottoshis",
        icon: faMedium,
        title: "Medium"
      }
    ];
    
    const smartContracts = [
      {
        label: "Collection",
        href: `https://${contractChainExplorer}/address/${contractAddress}`
      },
      {
        label: "Adopt-A-Marmot",
        href: `https://${adoptContractChainExplorer}/address/${adoptContractAddress}`
      },
      
      {
        label: "MarmottoShow",
        href: `https://${marmottoshowContractChainExplorer}/address/${marmottoshowContractAddress}`
      }
    ];
    
    const Footer = React.memo(() => {
      const year = new Date().getFullYear();
    
      return (
        <footer className='text-center text-capitalize'>
          <Container fluid>
            <Row>
              <Col lg={12} className="mb-4">
                <img src={Logo} className="mb-4" alt="Marmottoshis Logo" />
                <h3 className='mb-2'>© {year} Marmottoshis</h3>
                <Nav className="justify-content-center">
                  {socialLinks.map(({ href, icon, title }) => (
                    <Nav.Item key={title}>
                      <Nav.Link href={href} target="_blank" rel="noreferrer" className="px-2" title={title}>
                        <FontAwesomeIcon icon={icon} />
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <span className="sc">
                  <b>Smart Contracts</b> : 
                  {smartContracts.map((contract, index) => (
                    <React.Fragment key={contract.label}>
                      <a href={contract.href} target="_blank" rel="noreferrer"> {contract.label}</a>
                      {index < smartContracts.length - 1 && ' | '}
                    </React.Fragment>
                  ))}
                </span><br />
                <span className="made-with-love">
                  DApp v.{version} made with ❤️ by <a href="https://profile.rpgmax.fr" target="_blank" rel="noreferrer">RpGmAx</a>
                </span>
              </Col>
            </Row>
          </Container>
        </footer>
      );
    });
    
    export default Footer;