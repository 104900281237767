import { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav, Container, Navbar } from "react-bootstrap";

import Logo from "../assets/logo-128.png";

const Menu = () => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const navLinks = useMemo(
    () => [
      { to: "/", label: "Home" },
      { to: "/dashboard", label: "Dashboard" },
      { to: "/burn", label: "Burn & Redeem" },
    ],
    []
  );

  const handleNavClick = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="dm-navbar navbar-dark"
      sticky="top"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={handleNavClick}>
          <img
            alt=""
            src={Logo}
            className="d-inline-block align-middle my-0 me-2"
            width="54"
          />
          Marmottoshis
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav" className="my-2">
          <Nav className="ms-auto">
            {navLinks.map(({ to, label }) => (
              <Nav.Link
                key={to}
                as={Link}
                to={to}
                className={location.pathname === to ? "active" : "inactive"}
                onClick={handleNavClick}
              >
                {label}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
