import { Container, Row, Col } from "react-bootstrap";

import Meta from '../components/Meta'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons'

import DocMarmott from "../assets/team/doc-marmott.jpg";
import Nekr from "../assets/team/nekr.jpg";
import RpGmAx from "../assets/team/rpgmax.jpg";
import From0To10K from "../assets/team/from0to10k.jpg";
import TheCryptomath from "../assets/team/thecryptomath.jpg";
import Shaax from "../assets/team/shaax.jpg";
import AnnoMortem from "../assets/team/annomortem.jpg";
import Boukal from "../assets/team/boukal.jpg";
import Koro from "../assets/team/koro.jpg";

import TeamMember from '../components/TeamMember';

const Team = () => {

    const pageTitle = 'Marmottoshis - L\'équipe'

    return (
        <>
            <Meta title={pageTitle} />

            <Container className="mt-4">

                <Row className="mb-4 ">
                    <Col lg={12}>
                        <h1 className="text-center my-4 page-title">L'équipe</h1>
                    </Col>
                </Row>

                <Row className="text-center justify-content-md-center">
                    <TeamMember
                        image={DocMarmott}
                        name="Doc Marmott"
                        role="Fondateur"
                        links={[
                            { url: "https://linktr.ee/docmarmott", icon: faLink },
                            { url: "https://twitter.com/DocMarmott", icon: faXTwitter },
                        ]}
                    />

                    <TeamMember
                        image={Nekr}
                        name="0xNekr"
                        role="Dev. Smart Contract"
                        links={[
                            { url: "https://linktr.ee/nekr_", icon: faLink },
                            { url: "https://twitter.com/0xNekr", icon: faXTwitter },
                        ]}
                    />

                    <TeamMember
                        image={RpGmAx}
                        name="RpGmAx"
                        role="Dev. Smart Contract & DApp"
                        links={[
                            { url: "https://profile.rpgmax.fr", icon: faLink },
                            { url: "https://twitter.com/RpGmAx", icon: faXTwitter },
                        ]}
                    />
                </Row>

                <Row className="text-center justify-content-md-center">
                    <TeamMember
                        image={From0To10K}
                        name="From 0 To 10K"
                        role="Gardien du wallet"
                        links={[
                            { url: "https://aucoindubloc.com", icon: faLink },
                            { url: "https://twitter.com/From0To10k", icon: faXTwitter },
                        ]}
                    />

                    <TeamMember
                        image={TheCryptomath}
                        name="TheCryptomath"
                        role="Gardien du wallet"
                        links={[
                            { url: "https://t.me/TheCryptomath", icon: faTelegram },
                            { url: "https://twitter.com/TheCryptomath", icon: faXTwitter },
                        ]}
                    />
                    <TeamMember
                        image={Shaax}
                        name="Shaax"
                        role="Dev Système"
                        links={[
                            { url: "https://twitter.com/ShaaxLive", icon: faXTwitter },
                        ]}
                    />
                </Row>

                <Row className="text-center justify-content-md-center">
                    <TeamMember
                        image={AnnoMortem}
                        name="Anno Mortem"
                        role="Core Team"
                        links={[
                            { url: "https://twitter.com/AnnoMortem", icon: faXTwitter },
                        ]}
                    />

                    <TeamMember
                        image={Boukal}
                        name="Boukal"
                        role="Core Team"
                        links={[
                            { url: "https://twitter.com/boukal1", icon: faXTwitter },
                        ]}
                    />
                    <TeamMember
                        image={Koro}
                        name="Koro"
                        role="Core Team"
                        links={[
                            { url: "https://twitter.com/MaitreKoro", icon: faXTwitter },
                        ]}
                    />
                </Row>

            </Container>


        </>
    )
}

export default Team