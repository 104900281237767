import { memo } from "react";
import { Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HomeCard = memo(({ image, title, buttonLink, icon, external }) => {
  const ButtonComponent = external ? "a" : Link;
  const buttonAttributes = external
    ? { href: buttonLink, target: "_blank", rel: "noreferrer" }
    : { to: buttonLink };

  return (
    <Col sm={5} lg={6} xl={6} xxl={3}>
      <Card className="mb-4">
        <Card.Img variant="top" src={image} />
        <Card.Body>
          <ButtonComponent
            {...buttonAttributes}
            className="btn btn-dark d-flex align-items-center justify-content-center text-nowrap"
            style={{
              fontSize: window.innerWidth <= 1680 ? "0.9rem" : "1rem",
            }}
          >
            <FontAwesomeIcon
              icon={icon}
              className="flex-shrink-0 me-2"
              color="brown"
            />
            <span className="text-truncate">{title}</span>
          </ButtonComponent>
        </Card.Body>
      </Card>
    </Col>
  );
});

export default HomeCard;
