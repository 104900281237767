import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Meta from "../components/Meta";
import {
  faCoins,
  faFire,
  faBookOpen,
  faPeopleGroup,
  faStore,
  faBaby,
  faPaintBrush,
} from "@fortawesome/free-solid-svg-icons";

import { useReadContract } from "wagmi";

import Marmot from "../assets/homepage-marmotV4.png";
import MarketPlaceMarmot from "../assets/homepage-marketplace-marmot.jpg";
import SwapMarmot from "../assets/homepage-swap-marmot.jpg";
import AdoptMarmot from "../assets/homepage-adopt-marmot.jpg";
import MarmottoShow from "../assets/homepage-marmottoshow.jpg";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";

import abi from "../contracts/ABI.json";

import HomeCard from "../components/HomeCard";

import FooterBlock from "../components/FooterBlock";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

const Home = () => {
  const pageTitle = "Marmottoshis : Tirelires backées par du BTC";

  const [displayedSupply, setDisplayedSupply] = useState(777);

  const { data: onChainTotalSupply } = useReadContract({
    address: contractAddress,
    abi: abi,
    functionName: "totalSupply",
    chainId: 1,
  });

  useEffect(() => {
    if (onChainTotalSupply) {
      const newSupply = parseInt(onChainTotalSupply);

      const duration = 2000;
      const start = displayedSupply;
      const end = newSupply;
      const startTime = Date.now();

      const updateNumber = () => {
        const now = Date.now();
        const progress = Math.min((now - startTime) / duration, 1);

        const easeOutQuad = (t) => t * (2 - t);
        const easedProgress = easeOutQuad(progress);

        const current = Math.round(start + (end - start) * easedProgress);
        setDisplayedSupply(current);

        if (progress < 1) {
          requestAnimationFrame(updateNumber);
        }
      };

      requestAnimationFrame(updateNumber);
    }
  }, [onChainTotalSupply]);

  return (
    <>
      <Meta title={pageTitle} />

      <Container fluid>
        <Row>
          <Col xxl={9} className="p-sm-5 mx-auto mt-4">
            <Row>
              <Col lg={3} className="text-center">
                <MouseParallaxContainer>
                  <MouseParallaxChild factorX={0.05} factorY={0.05}>
                    <img src={Marmot} className="homepage-marmot" />
                  </MouseParallaxChild>
                </MouseParallaxContainer>
              </Col>
              <Col lg={9} className="p-4">
                <h1 className="total_nfts page-title">
                  {displayedSupply?.toString()} Tirelires
                </h1>
                <p className="lead">
                  Initialement 777 Tirelires (21 modèles en 37 exemplaires),
                  backées par du BTC accumulé chaque mois en DCA.
                  <br />
                  Supply qui se réduit à chaque burn !
                </p>

                <Row className="nfts-cards justify-content-center">
                  <h2 className="text-center">Découvrez aussi...</h2>

                  <HomeCard
                    image={AdoptMarmot}
                    title="Adopt A Marmot"
                    buttonLink="/adopt"
                    icon={faBaby}
                    external={false}
                  />

                  {
                    <HomeCard
                      image={MarmottoShow}
                      title="Marmotto Show"
                      buttonLink="/marmottoshow"
                      icon={faPaintBrush}
                      external={false}
                    />
                  }

                  <HomeCard
                    image={SwapMarmot}
                    title="Swap"
                    buttonLink="/trocador"
                    icon={faCoins}
                    external={false}
                  />

                  <HomeCard
                    image={MarketPlaceMarmot}
                    title="Marketplace"
                    buttonLink="https://marmottoshis.wlbl.xyz"
                    icon={faStore}
                    external={true}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div className="divider"></div>

      <Container>
        <Row className="mt-5 mb-4 justify-content-center">
          <FooterBlock
            icon={faBookOpen}
            title="White Paper"
            description="Le projet dans ses moindres détails."
            link="https://medium.com/@Marmottoshis/wasat-paper-a2e22ae397d9"
            external={true}
          />
          <FooterBlock
            icon={faPeopleGroup}
            title="Team"
            description="L'équipe derrière Marmottoshis."
            link="/team"
            external={false}
          />
          <FooterBlock
            icon={faFire}
            title="Burn The Floor"
            description="Une protection boostant la rareté évolutive."
            link="https://medium.com/@Marmottoshis/burn-the-floor-512b07b3e84b"
            external={true}
          />
        </Row>
      </Container>
    </>
  );
};

export default Home;
