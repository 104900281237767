import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TeamMember = ({ image, name, role, links }) => (
  <Col lg={3} md={12} className="mb-5">
    <div className="rounded shadow-sm py-5 px-4 team-member">
      <img
        src={image}
        width="100"
        className="img-fluid rounded-circle mb-3 member-pfp shadow-sm"
        alt={`${name}'s profile`}
      />
      <h5 className="mb-0 text-black">{name}</h5>
      <span className="member-role text-uppercase text-muted">{role}</span>
      <ul className="social mb-0 list-inline mt-3">
        {links.map(({ url, icon }) => (
          <li className="list-inline-item" key={url}>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="member-link"
            >
              <FontAwesomeIcon icon={icon} className="text-black" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  </Col>
);

export default TeamMember;
