import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { Toaster } from 'react-hot-toast';
import Layout from "./layout/Layout";

import Home from "./pages/Home";
import Team from "./pages/Team";
import Adopt from './pages/Adopt';
import Trocador from "./pages/Trocador";
import Dashboard from "./pages/Dashboard";
import Burn from "./pages/Burn";
import Marmot from "./pages/Marmot";
import Marmottoshow from "./pages/Marmottoshow";

import './styles/App.css';
import '@rainbow-me/rainbowkit/styles.css';

import { WagmiProvider, http } from 'wagmi'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'

import { mainnet, arbitrum, polygon } from 'wagmi/chains';

import { darkTheme } from '@rainbow-me/rainbowkit';


const config = getDefaultConfig({
  appName: 'Marmottoshis',
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
  chains: [mainnet, arbitrum, polygon],
  transports: {
    [mainnet.id]: http(`https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`),
    [arbitrum.id]: http(`https://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`),
    [polygon.id]: http(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`)
  },
})

const queryClient = new QueryClient()

const App = () => {

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme({ borderRadius: 'small', overlayBlur: 'small' })} modalSize="compact" coolMode>
          <Router>
            <Layout>
              <Routes>
                {[
                  { path: "/", element: <Home />, exact: true },
                  { path: "/Burn", element: <Burn /> },
                  { path: "/Team", element: <Team /> },
                  { path: "/Adopt", element: <Adopt /> },
                  { path: "/Trocador", element: <Trocador /> },
                  { path: "/Marmottoshow", element: <Marmottoshow /> },
                  { path: "/Marmottoshow/:tokenId", element: <Marmottoshow /> },
                  { path: "/Marmot/:id", element: <Marmot /> },
                  { path: "/Dashboard", element: <Dashboard /> },
                  { path: "*", element: <Navigate to="/" /> }
                ].map(({ path, element, exact }) => (
                  <Route key={path} path={path} element={element} exact={exact} />
                ))}
              </Routes>
              <Toaster
                position="top-center"
                toastOptions={{
                  style: {
                    border: '1px solid #4c2b18',
                    padding: '16px',
                    color: '#4c2b18'
                  },
                  iconTheme: {
                    primary: '#4c2b18',
                    secondary: '#FFF'
                  }
                }}
                reverseOrder={false}
              />
            </Layout>
          </Router>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;