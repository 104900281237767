import { memo } from "react";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

const FooterBlock = memo(({ icon, title, description, link, external }) => {
  const LinkComponent = external ? 'a' : Link;
  const linkAttributes = external
    ? { href: link, target: "_blank", rel: "noreferrer" }
    : { to: link };
  return (
    <Col xxl={4} md={4} xs={12} className="text-center mb-4">
      <h4 className="fw-bold mb-3">
        <FontAwesomeIcon icon={icon} color="brown" className="me-2" size="lg" fixedWidth />
        <LinkComponent {...linkAttributes}>{title}</LinkComponent>
      </h4>
      <p>{description}</p>
    </Col>
  );
});

export default FooterBlock;