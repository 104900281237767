import { useEffect, useState, useCallback } from "react";

import { Container, Row, Col, Card } from "react-bootstrap";

import Meta from "../components/Meta";

import toast from "react-hot-toast";

import BurnForm from "../components/BurnForm";

import { ConnectButton, useChainModal } from "@rainbow-me/rainbowkit";
import {
  useAccount,
  usePublicClient,
  useSimulateContract,
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import { mainnet } from "wagmi/chains";

import abi from "../contracts/ABI.json";

import BurningMarmot from "../assets/burning-marmot.jpg";

const nodeJsServerUrl = process.env.REACT_APP_NODEJS_LINK;

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

const nft_families = JSON.parse(process.env.REACT_APP_NFT_FAMILIES);
const nft_names = JSON.parse(process.env.REACT_APP_NFT_NAMES);
const nft_images = JSON.parse(process.env.REACT_APP_NFT_IMAGES);
const version = process.env.REACT_APP_VERSION;

const Burn = () => {
  const pageTitle = "Marmottoshis - Burn & Redeem";

  const [userNFTs, setUserNFTs] = useState([]);

  const { address, isConnected, chain } = useAccount();

  const publicClient = usePublicClient({ chainId: mainnet.id });

  const { openChainModal } = useChainModal();

  // Burn
  const [burnBitcoinAddress, setBurnBitcoinAddress] = useState();
  const [burnNFTFamilyID, setBurnNFTFamilyID] = useState();
  const [burnSats, setBurnSats] = useState();
  const [isBurnLaunched, setIsBurnLaunched] = useState(false);

  const { data: burnData, isSuccess: isSimulateSuccess } = useSimulateContract({
    address: contractAddress,
    abi: abi,
    functionName: "burnAndRedeem",
    args: [parseInt(burnNFTFamilyID), burnBitcoinAddress],
    query: { enabled: Boolean(burnBitcoinAddress) },
  });

  const {
    data: hash,
    isError: isBurnError,
    writeContract: burn,
  } = useWriteContract();
  const { isLoading: isBurnLoading, isSuccess: isBurnSuccess } =
    useWaitForTransactionReceipt({ hash });

  // Soit on lance le burn, soit on reset les états des variables concernées
  useEffect(() => {
    if (isBurnLaunched) {
      toast.loading(
        () => (
          <div>
            <small>
              Burn d'un exemplaire de <b>{nft_names[burnNFTFamilyID - 1]}</b>{" "}
              avec redeem de <b>{burnSats} sats</b> à destination de :<br />
              <b>{burnBitcoinAddress}</b>
            </small>
          </div>
        ),
        { style: { wordBreak: "break-word" } }
      );
    } else {
      setBurnBitcoinAddress(null);
      setBurnNFTFamilyID(null);
      setBurnSats(null);
    }
  }, [isBurnLaunched]);

  useEffect(() => {
    if (burnData) {
      burn?.(burnData?.request);
    }
  }, [isSimulateSuccess]);

  // Burn en erreur
  useEffect(() => {
    if (isBurnError) {
      toast.dismiss();
      setIsBurnLaunched(false);
      toast.error(
        () => (
          <div>
            <small>
              Burn de <b>{nft_names[burnNFTFamilyID - 1]}</b> annulé !
            </small>
          </div>
        ),
        { style: { wordBreak: "break-word" } }
      );
    }
  }, [isBurnError]);

  // Burn en cours
  useEffect(() => {
    if (isBurnLoading) {
      toast.dismiss();
      toast.loading(
        () => (
          <div>
            <small>
              Burn de <b>{nft_names[burnNFTFamilyID - 1]}</b> en cours !
            </small>
          </div>
        ),
        { style: { wordBreak: "break-word" } }
      );
    }
  }, [isBurnLoading]);

  // Burn réussi
  useEffect(() => {
    if (isBurnSuccess) {
      toast.dismiss();
      toast.success(
        () => (
          <div>
            <small>
              Burn de <b>{nft_names[burnNFTFamilyID - 1]}</b> réussi !
            </small>
          </div>
        ),
        { style: { wordBreak: "break-word" } }
      );
      // Alerte
      const burn_datas = {
        name: nft_names[burnNFTFamilyID - 1],
        tx: hash,
        satoshis: burnSats,
      };
      fetch(`${nodeJsServerUrl}/burn`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 8d3a5c78-66ff-4526-9d85-ea00a0f40cf4",
        },
        body: JSON.stringify(burn_datas),
      })
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
      setIsBurnLaunched(false);
      getUserNFTs();
    }
  }, [isBurnSuccess]);

  // Nettoyage sortie de page
  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  // Récupération des NFTs de l'utilisateur
  const getUserNFTs = async () => {
    const addresses = [];
    for (var i = 1; i <= 21; i++) {
      addresses.push(address);
    }

    if (!isConnected) return;
    try {
      const list = await publicClient.readContract({
        address: contractAddress,
        abi: abi,
        functionName: "balanceOfBatch",
        args: [addresses, nft_families],
      });

      var nfts = String(list).split(",").filter(Boolean);

      if (nfts.length >= 1) {
        setUserNFTs(nfts);
      } else {
        setUserNFTs();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Récupération des satoshis associés à une famille
  const getSatsbyID = async (nft_family_id) => {
    if (!isConnected) return;
    try {
      const amount = await publicClient.readContract({
        address: contractAddress,
        abi: abi,
        functionName: "redeemableById",
        args: [nft_family_id],
      });
      if (amount >= 1) {
        return parseInt(amount);
      }
    } catch (err) {
      return 0;
    }
  };

  // Affichage de chaque NFT
  const SingleNFT = ({ qty, nft_family_id }) => {
    var nft_index = nft_family_id - 1;

    const [nftSats, setNftSats] = useState();

    useEffect(() => {
      getSatsbyID(nft_family_id).then(setNftSats);
    }, [nft_family_id]);

    if (qty < 1) return null;

    return (
      <>
        <Col sm={6} md={4}>
          <Card className="mb-4">
            {nft_index == 1 ? (
              <video
                autoPlay
                muted
                loop
                poster={`/images/nfts/${nft_images[nft_index]}.jpg?v=${version}`}
              >
                <source
                  src={`/images/nfts/${nft_images[nft_index]}.mp4?v=${version}`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <Card.Img
                variant="top"
                src={`/images/nfts/${nft_images[nft_index]}?v=${version}`}
              />
            )}
            <Card.Body>
              <Card.Title>{nft_names[nft_index]}</Card.Title>

              <BurnForm
                isBurnLaunched={isBurnLaunched}
                setIsBurnLaunched={setIsBurnLaunched}
                setBurnBitcoinAddress={setBurnBitcoinAddress}
                nft_family_id={nft_family_id}
                setBurnNFTFamilyID={setBurnNFTFamilyID}
                sats={nftSats}
                setBurnSats={setBurnSats}
              />
            </Card.Body>
            <Card.Footer>
              {qty} en votre possession
              <br />
              {nftSats >= 1 ? <>{nftSats} Satoshis adossés / tirelire</> : null}
              <br />
            </Card.Footer>
          </Card>
        </Col>
      </>
    );
  };

  // Effects
  useEffect(() => {
    getUserNFTs();
  }, [address]);

  const m_burnUI = () => {
    const filteredItems = Array.from(userNFTs.entries()).filter(
      ([index, qty]) => qty >= 1
    );
    const listItems = filteredItems.map(([index, qty]) => (
      <SingleNFT qty={qty} key={index + 1} nft_family_id={index + 1} />
    ));
    return (
      <>
        <Row className="nfts-cards justify-content-center">{listItems}</Row>
        {userNFTs.length >= 1
          ? userNFTs.reduce((a, b) => a + b, 0) == 0 && (
              <h4>Vous n'avez aucun Marmottoshi sur ce wallet.</h4>
            )
          : null}
      </>
    );
  };

  const burnUI = () => {
    if (!isConnected) return;

    return (
      <div className="text-center mb-5">
        {chain.id !== mainnet.id ? (
          <>
            <p>
              - Le burn se passe sur Ethereum, veuillez{" "}
              <a href="#" onClick={openChainModal}>
                changer de blockchain
              </a>{" "}
              -
            </p>
          </>
        ) : (
          <>{m_burnUI()}</>
        )}
      </div>
    );
  };

  return (
    <>
      <Meta title={pageTitle} />

      <Container>
        <Row>
          <Col sm={12} className="mt-4">
            {" "}
            <h1 className="text-center my-4 page-title">Burn & Redeem</h1>
            <p className="text-center mb-4">
              Brûlez <u>définitivement</u> un Marmottoshi pour obtenir les
              Satoshis adossés à ce dernier.
            </p>
            <div className="text-center">
              <img
                src={BurningMarmot}
                width="320"
                className="mb-4 burning-marmot"
              />
            </div>
            <div className="connect-zone my-2">
              <ConnectButton chainStatus="icon" label="Connecter son wallet" />
            </div>
          </Col>
          <Col sm={12} className="mt-4">
            {isConnected ? burnUI() : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Burn;
