import { useEffect, useState, memo } from "react";

import { Container, Row, Col, Card, Form } from "react-bootstrap";

import Meta from "../components/Meta";

import { useReadContract, usePublicClient } from "wagmi";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire, faSpinner, faStore } from "@fortawesome/free-solid-svg-icons";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import abi from "../contracts/ABI.json";

import { mainnet } from "viem/chains";

const NFT_NAMES = JSON.parse(process.env.REACT_APP_NFT_NAMES);
const NFT_IMAGES = JSON.parse(process.env.REACT_APP_NFT_IMAGES);
const VERSION = process.env.REACT_APP_VERSION;
const NODEJS_SERVER_URL = process.env.REACT_APP_NODEJS_LINK;
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const PROJECT_BTC_ADDRESS = process.env.REACT_APP_PROJECT_BTC_ADDRESS;

const Dashboard = () => {
  const pageTitle = "Marmottoshis - Dashboard";

  const [pastRedeems, setPastRedeems] = useState([]);
  const [pastSatoshis, setPastSatoshis] = useState([]);

  const [nfts, setNFTs] = useState([]);
  const [sortBy, setSortBy] = useState("id");

  const publicClient = usePublicClient({ chainId: 1 });

  const { data: totalSupply } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: abi,
    functionName: "totalSupply",
    chainId: mainnet.id,
  });

  const getPastRedeems = async () => {
    try {
      const response = await fetch(`${NODEJS_SERVER_URL}/redeems`);
      if (!response.ok) throw new Error(response.status);

      const past_redeems = await response.json();
      const past_redeems_array = Object.keys(past_redeems).map((key) => {
        const args = past_redeems[key].args;
        const nft = args[1].toString();

        return {
          tx: past_redeems[key].transactionHash,
          from: args[0],
          nft: nft,
          nft_name: NFT_NAMES[nft - 1],
          sats: args[4],
          btc_address: args[3],
          timestamp: past_redeems[key].timestamp,
        };
      });

      setPastRedeems(past_redeems_array.reverse());
    } catch (error) {
      console.log("Error fetching data: ", error);
    }
  };

  const getPastSatoshisAdd = async () => {
    try {
      const response = await fetch(`${NODEJS_SERVER_URL}/satoshis`);
      if (!response.ok) throw new Error(response.status);
      const jsonData = await response.json();
      const quarterData = marmottoshisLoveQuarters(jsonData);
      setPastSatoshis(quarterData);
    } catch (error) {
      console.log("Error fetching data: ", error);
    }
  };

  const marmottoshisLoveQuarters = (jsonData) => {
    const getQuarter = (month) => {
      const quarters = [
        "Q1",
        "Q1",
        "Q1",
        "Q2",
        "Q2",
        "Q2",
        "Q3",
        "Q3",
        "Q3",
        "Q4",
        "Q4",
        "Q4",
      ];
      return quarters[month - 1];
    };

    const getCurrentQuarter = () => {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      return `${getQuarter(currentMonth)} ${currentYear}`;
    };

    const currentQuarter = getCurrentQuarter();
    let transactionsByQuarter = {};
    let currentQuarterTransactions = [];

    jsonData.transactions.forEach((transaction) => {
      const [day, month, year] = transaction.date.split("/").map(Number);
      const quarter = `${getQuarter(month)} ${year}`;

      if (quarter === currentQuarter) {
        currentQuarterTransactions.push(transaction);
      } else {
        if (!transactionsByQuarter[quarter]) {
          transactionsByQuarter[quarter] = {
            date: quarter,
            Satoshis: 0,
            "Cumul en ₿": 0,
          };
        }

        transactionsByQuarter[quarter].Satoshis += transaction.Satoshis;
        transactionsByQuarter[quarter]["Cumul en ₿"] =
          transaction["Cumul en ₿"];
      }
    });

    const mergedTransactions = Object.values(transactionsByQuarter).concat(
      currentQuarterTransactions
    );

    return mergedTransactions;
  };

  const Populate = async () => {
    const nfts = [];

    const promises = Array.from({ length: 21 }, async (_, id) => {
      const supply = await publicClient.readContract({
        address: CONTRACT_ADDRESS,
        abi: abi,
        functionName: "supplyByID",
        args: [id + 1],
      });

      const nft = { id: id + 1, supply: parseInt(supply) };

      if (supply >= 1) {
        const sats = await publicClient.readContract({
          address: CONTRACT_ADDRESS,
          abi: abi,
          functionName: "redeemableById",
          args: [id + 1],
        });
        nft.sats = parseInt(sats);
      } else {
        nft.sats = 0;
      }

      return nft;
    });

    const results = await Promise.all(promises);
    nfts.push(...results);

    setNFTs(nfts);
    getPastSatoshisAdd();
  };

  const SingleNFT = memo(({ nft_family_id, nftSupply, nftSats }) => {
    var nft_index = nft_family_id - 1;
    return (
      <Col sm={6} md={4}>
        <Card className="mb-4">
          {nft_index == 1 ? (
            <video
              autoPlay
              muted
              loop
              poster={`/images/nfts/${NFT_IMAGES[nft_index]}.jpg?v=${VERSION}`}
            >
              <source
                src={`/images/nfts/${NFT_IMAGES[nft_index]}.mp4?v=${VERSION}`}
                type="video/mp4"
              />
            </video>
          ) : (
            <Card.Img
              variant="top"
              src={`/images/nfts/${NFT_IMAGES[nft_index]}?v=${VERSION}`}
            />
          )}
          <Card.Body>
            <Card.Title>{NFT_NAMES[nft_index]}</Card.Title>
            {nftSupply >= 1 ? (
              <a
                href={`${process.env.REACT_APP_MP_LINK}:${CONTRACT_ADDRESS}:${nft_family_id}`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-dark btn-lg"
                title="Voir sur la marketplace"
              >
                <FontAwesomeIcon
                  icon={faStore}
                  className="flex-shrink-0 me-3"
                  color="brown"
                  size="lg"
                />
                Marketplace
              </a>
            ) : (
              <a
                href="#"
                className="btn btn-dark btn-lg disabled"
                title="Plus disponible"
              >
                <FontAwesomeIcon
                  icon={faStore}
                  className="flex-shrink-0 me-3"
                  color="brown"
                  size="lg"
                />
                Marketplace
              </a>
            )}
          </Card.Body>
          <Card.Footer>
            {nftSupply >= 1 ? (
              <>
                {nftSupply}{" "}
                {nftSupply >= 2 ? <>exemplaires</> : <>exemplaire</>}
              </>
            ) : (
              <>Plus</>
            )}{" "}
            en circulation
            <br />
            {nftSats >= 1 ? <>{nftSats} Satoshis adossés</> : null}
            <br />
          </Card.Footer>
        </Card>
      </Col>
    );
  });

  const ListItem = memo(({ redeem }) => (
    <li>
      <FontAwesomeIcon icon={faFire} color="brown" size="lg" fixedWidth fade />
      <a href={`https://etherscan.io/tx/${redeem.tx}`} target="_blank">
        {new Date(redeem.timestamp * 1000).toLocaleDateString("fr-FR")}
      </a>{" "}
      &bull; Burn d'une tirelire <strong>{redeem.nft_name}</strong> et obtention
      de <strong>{redeem.sats}</strong> Satoshis sur{" "}
      <a
        href={`https://www.blockchain.com/btc/address/${redeem.btc_address}`}
        target="_blank"
      >
        {redeem.btc_address == PROJECT_BTC_ADDRESS
          ? "la tirelire du projet (merci 🙏)"
          : "ce wallet"}
      </a>
      .
    </li>
  ));

  // Effects
  useEffect(() => {
    getPastRedeems();
    Populate();
  }, []);

  const m_dashboardUI = () => {
    const sortedNFTs = [...nfts].sort((a, b) => {
      if (sortBy === "nftSupply") return a.supply - b.supply;
      if (sortBy === "nftSats") return b.sats - a.sats;
      return a.id - b.id; // Default sort by ID
    });

    const listItems = sortedNFTs.map((nft) => (
      <SingleNFT
        key={nft.id}
        nft_family_id={nft.id}
        nftSupply={nft.supply}
        nftSats={nft.sats}
      />
    ));

    return (
      <>
        <h4 className="mb-4">Supply : {totalSupply?.toString()}/777</h4>
        <Form.Group className="mb-5 d-flex justify-content-center">
          <Form.Label className="me-2 mt-2" style={{ fontSize: "0.9rem" }}>
            Trier par :
          </Form.Label>
          <Form.Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="w-auto"
            style={{
              color: "#4c2b18",
              border: "1px solid black",
              fontSize: "0.9rem",
            }}
          >
            <option value="id">ID</option>
            <option value="nftSupply">Exemplaires</option>
            <option value="nftSats">Satoshis adossés</option>
          </Form.Select>
        </Form.Group>
        {nfts.length < 21 ? (
          <>
            <FontAwesomeIcon
              icon={faSpinner}
              color="brown"
              size="lg"
              fixedWidth
              spin
              fade
            />
            <br />
            Chargement des données On-Chain en cours, patience...
          </>
        ) : (
          <Row className="nfts-cards">{listItems}</Row>
        )}
      </>
    );
  };

  const BurnsUI = memo(({ pastRedeems }) => (
    <>
      <h4 className="mb-4">Burns</h4>
      {pastRedeems.length >= 1 ? (
        <ul className="redeems m-0 p-0 text-start">
          {pastRedeems.map((redeem, index) => (
            <ListItem key={index} redeem={redeem} />
          ))}
        </ul>
      ) : (
        <>Chargement en cours, patience...</>
      )}
    </>
  ));

  const SatoChart = ({ data }) => {
    if (!data || data.length <= 1) {
      return "Chargement en cours, patience...";
    }

    return (
      <>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 50,
              left: 50,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" stroke="#FFF" />
            <YAxis yAxisId="left" orientation="left" stroke="#d9bc3c" />
            <YAxis yAxisId="right" orientation="right" stroke="#e49a72" />
            <Tooltip
              contentStyle={{
                backgroundColor: "#4c2b18",
                border: "none",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
              cursor={false}
            />
            <Legend />
            <Bar dataKey="Satoshis" yAxisId="left" fill="#d9bc3c" />
            <Bar dataKey="Cumul en ₿" yAxisId="right" fill="#e49a72" />
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  };

  const dashboardUI = () => {
    return (
      <>
        <h1 className="text-center my-4 page-title">Dashboard</h1>
        <Row>
          <Col sm={12} className="relief mb-5 p-4">
            <BurnsUI pastRedeems={pastRedeems} />
          </Col>
          <Col sm={12} className="relief mb-5 p-4">
            <h4 className="mb-4">Redistribution de Satoshis</h4>
            <SatoChart data={pastSatoshis} />
          </Col>
          <Col sm={12} className="relief p-4">
            {m_dashboardUI()}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Meta title={pageTitle} />

      <Container>
        <Row>
          <Col sm={12} className="mt-4">
            {dashboardUI()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
